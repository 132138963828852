<template>
  <div>
    <el-dialog :visible.sync="dialogVisible"
               fullscreen
               :before-close="handleClose">
      <!-- <video :src="video"
             oncontextmenu="return false;"
             controls /> -->
      <video id="my-video"
             class="video-js vjs-default-skin vjs-big-play-button vjs-big-play-centered"
             controls
             preload="auto"
             poster="">
        <source :src="video"
                type="video/mp4">
      </video>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      video: '',
      dialogVisible: false
    }
  }
}
</script>

<style>
</style>