<template>
  <div class="lecture_warp">
    <TopCard ref="TopCardRef"
             text="考点讲解"
             :showBack="true" />
    <div class="subject_search">
      <div class="subject">
        <div v-for="item in subjectList"
             :class="{'active_subject':subjectId == item.subject_id}"
             @click="checkSubject(item)"
             :key="item.subject_id">
          {{item.subject_name}}
        </div>
      </div>
      <div class="search">
        <el-input placeholder="请输入视频名称"
                  v-model="keyword">
          <i class="el-icon-search"
             slot="suffix"
             @click="getData">
          </i>
        </el-input>
      </div>
    </div>
    <div class="list">
      <div class="video"
           v-for="item in filtList"
           :key="item.url">
        <div class="subject"
             :style="{'background':$subject_styles[subjectId].subject_backg_color,
               'color':$subject_styles[subjectId].text_color}">
          {{subject_name}}
        </div>
        <div class="name">
          {{item.teaching_video_name}}
        </div>
        <div class="start">
          <!-- <img src="@/assets/icons/icon-星星.png"
               v-for="item in item.star"
               :key="item"
               alt=""> -->
        </div>
        <div class="btn_wrap">
          <div class="btn"
               :style="{'background':$subject_styles[subjectId].text_color}"
               @click="showViedo(item)">
            <!-- 视频列表 -->
            播放
            <img src="@/assets/lecture/start.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <VideoList ref="VideoListRef" /> -->
    <!-- <VideoDialog ref="VideoDialogRef" /> -->
    <div class="pagination">
      <pagination ref="pagination"
                  :get-data="getData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="total" />
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import { getStudentSubject } from '@/api/subject.js'
import { knowledgeVideo } from '@/api/knowledgeVideo.js'
import { getCheckpointVideoList } from '@/api/checkpoint.js'
import VideoList from './components/video.vue'
import { mapState, mapMutations } from 'vuex'
import VideoDialog from '@/views/components/video/index.vue'
export default {
  data () {
    return {
      tableData: [],
      sourceCode: '',
      // subjectList: [],
      subjectId: '',
      keyword: '',
      total: 0,
      page: 1,
      size: 20,
      subject_name: '',
      list: []
    }
  },
  components: {
    TopCard, VideoList, VideoDialog
  },
  computed: {
    ...mapState(['subjectList']),
    filtList () {
      var list = JSON.parse(JSON.stringify(this.list))
      if (this.keyword !== '') {
        list = this.list.filter(item => {
          if (item.teaching_video_name.indexOf(this.keyword) != -1) {
            return item
          }
        })
      }
      this.total = list.length
      return list.splice((this.page - 1) * this.size, this.page * this.size)
    }
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    ...mapMutations(['SET_SUBJECT']),
    async getSubject () {
      if (this.subjectList.length == 0) {
        const { data } = await getStudentSubject()
        // this.subjectList = data.list
        this.SET_SUBJECT(data.list)
      }
      // const { data } = await getStudentSubject()
      // this.subjectList = data.list
      this.checkSubject(this.subjectList[0])
    },
    async getData (page, limit) {
      if (page || limit) {
        this.page = page
        this.size = limit
      }
      if (this.subjectId == '') return
      let params = {
        subject_id: this.subjectId,
      }
      const { data } = await getCheckpointVideoList(params)
      this.list = data.list
    },
    checkSubject (item) {
      this.subjectId = item.subject_id
      this.subject_name = item.subject_name
      this.getData()
    },
    showViedo (item) {
      // this.$refs.VideoDialogRef.video = item.video_link
      // this.$refs.VideoDialogRef.dialogVisible = true
      window.open(item.video_link, '_blank')
      return
      let params = {
        id: item.id,
        // is_knowledge: item.is_knowledge
      }
      this.$refs.VideoListRef.initList(params)
      this.$refs.VideoListRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.lecture_warp {
  background: #f6f6f6;
  .category {
    width: 100%;
    height: 80rem;
    display: flex;
    .label {
      width: 100rem;
      text-align: right;
      font-size: 20rem;
      font-weight: bold;
      color: #333333;
      height: 40rem;
      line-height: 40rem;
    }
    .categorys {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 20rem;
      font-weight: bold;
      justify-content: flex-start;
      div {
        margin-right: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        cursor: pointer;
      }
      .cative {
        background: #2196f3 !important;
        border-radius: 2rem !important;
        color: #ececec !important;
        padding: 0 20rem !important;
      }
    }
  }
  .subject_search {
    display: flex;
    height: 44rem;
    margin-bottom: 10rem;
    margin-top: 10rem;
    position: relative;
    .subject {
      flex: 1;
      display: flex;
      margin-left: 25rem;
      div {
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
        padding: 0 20rem;
        cursor: pointer;
      }
    }
    .search {
      // position: absolute;
      width: 31%;
      height: 44rem;
      margin-right: 50rem;
      // right: 50rem;
      ::v-deep .el-input--medium .el-input__inner {
        height: 44rem;
        line-height: 44rem;
        border-radius: 27rem;
        font-size: 18rem;
        font-weight: 500;
        color: #999999;
        background: white;
      }
      ::v-deep .el-icon-search {
        margin-right: 20rem;
        font-size: 22rem;
      }
      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10rem;
    height: calc(100vh - 225rem);
    overflow-y: auto;
    .video {
      width: 280rem;
      height: 250rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
      border-radius: 20rem;
      margin-left: 50rem;
      margin-top: 30rem;
      position: relative;
      .subject {
        width: 120rem;
        height: 44rem;
        background: #e6f0ff;
        border-radius: 1rem 1rem 15rem 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20rem;
        font-weight: bold;
        color: #2196f3;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      .name {
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
        margin: 30rem 30rem 10rem 30rem;
        height: 56rem;
        margin-top: 30rem;
      }
      .start {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20rem;
        width: 100%;
        img {
          width: 20rem;
          height: 20rem;
        }
      }
      .btn_wrap {
        display: flex;
        justify-content: center;
        margin-top: 20rem;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 40rem;
          font-size: 22rem;
          color: #ffffff;
          cursor: pointer;
          img {
            width: 24rem;
            height: 24rem;
            margin-left: 12rem;
          }
        }
      }
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;
    color: #2196f3 !important;
  }
}
</style>