
import { instance } from '@/utils/http.js'

// 知识点讲解列表
export const knowledgeVideo = function (params) {
  return instance({
    url: '/api/v1/public/get_explain_knowledge',
    method: 'get',
    params
  })
}

// 知识点视频列表
export const videoList = function (params) {
  return instance({
    url: '/api/v1/public/get_explain_video_list',
    method: 'get',
    params
  })
}

